export function addEntry(item, newEntry, ID) {
  // Fetch array and parse
  let array = JSON.parse(localStorage.getItem(item)) || []

  // Filter out any item that matches the new entry
  array = array.filter(entry => {
    return entry._id !== ID
  })

  // Add the new entry 
  array.push(newEntry)

  // Save it back to local storage
  localStorage.setItem(item, JSON.stringify(array))
}

export function deleteEntry(item, ID) {
  // Fetch array and parse
  let array = JSON.parse(localStorage.getItem(item)) || []

  // Filter out the item we want to remove
  array = array.filter(entry => {
    return entry._id !== ID
  })

  // Save it back to local storage
  localStorage.setItem(item, JSON.stringify(array))
}

export function getEntries(item) {
  let entries = localStorage.getItem(item)

  if (entries && entries.length > 0) {
    entries = JSON.parse(entries)
  } else {
    entries = false
  }

  return entries
}


export function lsGet(name, isJSON = false) {
  let value

  try {
    value = localStorage.getItem(name)
  } catch (e) {
    console.log(e)
  }

  if (isJSON) {
    try {
      value = JSON.parse(value)
    } catch (e) {
      console.log(e)
    }
  } else {
    if (!value || ["undefined", "null", "false"].some(el => value.includes(el))) {
      value = false
    } else if (["true"].some(el => value.includes(el))) {
      value = true
    }
  }

  return value
}

export function lsSet(data, isJSON = false) {
  data.forEach(item => {
    let name = item[0]
    let value = item[1]
    if (isJSON) value = JSON.stringify(value)
    localStorage.setItem(name, value)
  })
}
