import React, { useState, useEffect } from "react"
import { Redirect } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/react-hooks"

import { GET_API_BY_ID, UPDATE_API, ADD_API, DELETE_API } from "../utils/Database"
import { addEntry, deleteEntry } from "../utils/LocalStorage"
import EditablePage from "./detail/EditablePage"

export default function({ match }) {
  const [apiData, setApiData] = useState(false)
  const [redirectRoute, setRedirectRoute] = useState(null)
  const { data, loading } = useQuery(GET_API_BY_ID, { variables: { _id: match.params._id } })
  const [addAPI, addResult] = useMutation(ADD_API)
  const [updateAPI, updateResult] = useMutation(UPDATE_API)
  const [deleteAPI] = useMutation(DELETE_API)

  useEffect(() => {
    if (data && data.APIDetailById && data.APIDetailById._id) {
      const content = JSON.parse(data.APIDetailById.content)
      const Data = { ...data.APIDetailById, content }
      setApiData(Data)
    }
  }, [data, loading])

  const PlaceholderData = {
          path: "path",
          title: "Title",
          description: "Write a description",
          color: "#4AA1D3",
          icon: "🌟",
          content: { add_data: "here" }
        }

  const handleSave = ({ variables }) => {
    if (variables._id) {
      updateAPI({ variables })
    } else {
      addAPI({ variables })
    }
  }

  useEffect(() => {
    const added   = addResult.called
    let result = added ? addResult : updateResult

    if (result) {
      if (result.loading) {
        console.log(`Loading`)
        // Spinner
      }

      if (result.data) {
        const resultData = added ? result.data.addAPI : result.data.updateAPI

        const Path = resultData.path
        const ID = resultData._id
        const Route = `/api/${Path}?edit=${ID}`

        addEntry("my_apis", resultData, ID)
        setRedirectRoute(Route)
      }
    }
  }, [addResult, updateResult])

  const handleDelete = (_id) => {
    if (_id) {
      deleteAPI({ variables: { _id }})
      deleteEntry("my_apis", _id)
      setRedirectRoute("/")
    }
  }

  return (
    <>
      {redirectRoute ? <Redirect to={redirectRoute} /> : null}
      {apiData ? 
        <EditablePage key={1} mode={"edit"} data={apiData} editable={true} onSave={handleSave} onDelete={handleDelete} /> 
        : 
        <EditablePage key={0} mode={"create"} data={PlaceholderData} editable={true} onSave={handleSave} />       
      }
    </>
  )
}