import React, { useState } from "react"
import Styled, { keyframes } from "styled-components"

export default function({url, loading}) {
  const [copied, setCopied] = useState(false)
  const [hidden, setHidden] = useState(false)

  if (hidden) return false

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url)
    markCopied()
  };

  const markCopied = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  };

  return (
    <Container>
      <InnerContainer onClick={copyToClipboard}>
        <CardCover>
          <Label>{copied ? "Copied" : "Copy link"}</Label>
        </CardCover>
        {loading ? <Loading /> : <CardImage src={url} />}
      </InnerContainer>
      <CloseBtn onClick={() => setHidden(true)}>✗</CloseBtn>
    </Container>
  )
}

const Container = Styled.div`
  position: relative;
  width: 140px;
  margin-top: 12px;
  padding: 6px;
  background: white;
  border-radius: var(--radius_m);
  box-shadow: var(--shadow_l);
`

const InnerContainer = Styled.div`
  position: relative;
  border-radius: var(--radius_s);
  overflow: hidden;
`

const CardCover = Styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 100ms linear;

  ${Container}:hover & {
    cursor: pointer;
    opacity: 1;
  }
`

const Label = Styled.div`
  text-align: center;
  color: white;
  font-size: var(--font_size_1);
  letter-spacing: var(--font_space_1);
  font-weight: var(--font_weight_1_bold);
`

const CardImage = Styled.img`
  display: block;
  width: 100%;
  height: auto;
  border-radius: var(--radius_s);
`

const CloseBtn = Styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 28px;
  height: 28px;
  border-radius: 16px;
  font-size: 20px;
  background: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 100ms linear;

  ${Container}:hover & {
    opacity: 1;
  }

  &:hover {
    cursor: pointer;
    color: var(--color_text_error);
  }
`

const LoadingKeyframes = keyframes`
  0% {
    width: 0%
  }
  50% {
    width: 40%
  }
  80% {
    width: 60%
  }
  100% {
    width: 100%
  }
`

const Loading = Styled.div`
  height: 100%;
  min-height: 24px;
  width: 0%;
  background: var(--color_primary);
  animation: ${LoadingKeyframes} 4s ease-in-out;
  animation-fill-mode: forwards;
`