import React from "react"
import Styled from "styled-components"
import Twemoji from 'react-twemoji';
 
export default function({ primary, secondary, icon, color, link, small }) {
  return (
    <Container color={color} small={small} href={link}>
      <Icon small={small}>
        <Emoji><Twemoji>{icon}</Twemoji></Emoji>
        <IconBG color={color} />
      </Icon>
      <TextContainer>
        <Primary>{primary}</Primary>
        <Secondary>{secondary}</Secondary>
      </TextContainer>
      <ContainerBG color={color} />
    </Container>
  )
}

const Container = Styled.a`
  position: relative;
  width: 100%;
  padding: ${props => props.small ? "12px" : "16px"};
  display: flex;
  align-items: center;
  border-radius: ${props => props.small ? "0" : "var(--radius_m)"};
  transition: all 50ms ease-in-out;
  text-decoration: none;
  color: black;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
`

const ContainerBG = Styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.15;
  transition: all 50ms ease-in-out;

  ${Container}:hover & {
    background: ${props => props.color};
  }
`

const Icon = Styled.div`
  position: relative;
  width: ${props => props.small ? "32px" : "48px"};
  height: ${props => props.small ? "32px" : "48px"};
  margin-right: 12px;
  border-radius: 100px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Emoji = Styled.span`
  position: relative;
  z-index: 1;

  img {
    display: block;
    width: 50%;
    height: auto;
    margin: auto;
  }
`

const IconBG = Styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${props => props.color};
  opacity: 0.15;
`

const TextContainer = Styled.div`
  width: 100%;
`

const Primary = Styled.div`
  font-size: var(--font_size_1);
  letter-spacing: var(--font_space_1);
  font-weight: var(--font_weight_1_bold);
`

const Secondary = Styled.div`
  color: var(--color_text_secondary);
  font-size: var(--font_size_0);
  letter-spacing: var(--font_space_0);
  font-weight: var(--font_weight_0_light);
`
