import React from "react"
import Styled from "styled-components"
import ContentEditable from "react-contenteditable"

export default function({ urlbase, value, editable, onChange }) {
  const handleChange = e => {
    onChange(e.target.value)
  }

  return (
    <Container>
      <Bar>
        <UrlContainer>
          <UrlBase>{urlbase}</UrlBase>
          <ContentEditable html={value} disabled={!editable} onChange={handleChange} />
        </UrlContainer>
      </Bar>
    </Container>
  )
}

const Container = Styled.div`
  z-index: 10;
  position: relative;
  width: 100%;
  max-width: var(--grid_width);
  padding: var(--grid_padding);
  margin: 0 auto;
  margin-top: -28px;
`

const Bar = Styled.div`
  border-radius: var(--radius_m);
  box-shadow: var(--shadow_m);
  background: white;
  overflow: hidden;
`

const UrlBase = Styled.div`
  color: var(--color_text_secondary);
`

const UrlContainer = Styled.div`
  height: 56px;
  width: 100%;
  padding: 16px 24px;;
  border: none;
  font-size: var(--font_size_1);
  letter-spacing: var(--font_space_1);
  font-weight: var(--font_weight_1_bold);

  div {
    display: inline;
  }
`
