import React from "react"
import Styled from "styled-components"
import ContentEditable from "react-contenteditable"
import emojiTree from "emoji-tree"
import Twemoji from 'react-twemoji';

export default function({ value, editable, onChange }) {
  const stripEmoji = string => {
    let Emoji = emojiTree(string)
      .filter(v => v.type === "emoji")
      .pop()

    if (Emoji && Emoji.text) {
      return Emoji.text
    } else {
      const Emojis = ["🌟", "⭐️", "⚡️", "✨", "💫"]
      const RandomEmoji = Emojis[Math.floor(Math.random() * Emojis.length)]
      return RandomEmoji
    }
  }

  const handleChange = e => {
    const Emoji = stripEmoji(e.target.value)
    onChange(Emoji)
  }

  return (
    <Container>
      <Twemoji>
        <ContentEditable html={value} disabled={!editable} onChange={handleChange} />
      </Twemoji>
    </Container>
  )
}

const Container = Styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  padding-top: 4px;
  margin-bottom: 12px;
  border-radius: 100px;
  background: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 36px;

  img {
    display: block;
    width: 50%;
    height: auto;
    margin: auto;
  }
`
