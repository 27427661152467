import React from "react"
import Styled from "styled-components"

import { Colors } from "../../utils/Colors"

export default function({ value, editable, onChange }) {
  if (!editable) return null

  const ColorButtons = Colors.map(color => {
    return <ColorDot key={color} color={color} onClick={() => onChange(color)} />
  })

  return <Container>{ColorButtons}</Container>
}

const Container = Styled.div`
  position: absolute;
  bottom: 24px;
  right: 24px;
  width: 72px;
  padding: 6px;
  padding-right: 6px;
  border-radius: var(--radius_m);
  box-shadow: var(--shadow_m);
  background: rgba(0,0,0,0.8);
  font-size: 0;
`

const ColorDot = Styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 3px;
  border-radius: 4px;
  background: ${props => props.color};

  /* &:nth-child(1) { border-top-left-radius: 6px }
  &:nth-child(2) { border-top-right-radius: 6px }
  &:nth-last-child(2) { border-bottom-left-radius: 6px }
  &:nth-last-child(1) { border-bottom-right-radius: 6px } */

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`
