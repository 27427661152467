import { gql } from "apollo-boost"

export const GET_API_LIST = gql`
  {
    APIList {
      title
      description
      icon
      color
      path
      featured
      discoverable
    }
  }
`

export const GET_API = gql`
  query APIDetail($path: String!) {
    APIDetail(path: $path) {
      _id
      path
      title
      description
      color
      icon
      content
      featured
      discoverable
    }
  }
`

export const GET_API_BY_ID = gql`
  query APIDetailById($_id: String!) {
    APIDetailById(_id: $_id) {
      _id
      path
      title
      description
      color
      icon
      content
      featured
      discoverable
    }
  }
`

export const ADD_API = gql`
  mutation(
    $path: String!
    $title: String!
    $description: String
    $color: String
    $icon: String
    $content: String!
    $featured: Boolean
    $discoverable: Boolean
  ) {
    addAPI(
      path: $path
      title: $title
      description: $description
      color: $color
      icon: $icon
      content: $content
      featured: $featured
      discoverable: $discoverable
    ) {
      _id
      path
      title
      description
      color
      icon
      content
      featured
      discoverable
    }
  }
`

export const UPDATE_API = gql`
  mutation(
    $_id: String!
    $path: String!
    $title: String!
    $description: String
    $color: String
    $icon: String
    $content: String!
    $featured: Boolean
    $discoverable: Boolean
  ) {
    updateAPI(
      _id: $_id
      path: $path
      title: $title
      description: $description
      color: $color
      icon: $icon
      content: $content
      featured: $featured
      discoverable: $discoverable
    ) {
      _id
      path
      title
      description
      color
      icon
      content
      featured
      discoverable
    }
  }
`

export const DELETE_API = gql`
  mutation( $_id: String! ) {
    deleteAPI( _id: $_id ) {
      _id
      path
      title
    }
  }
`
