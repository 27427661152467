import React from "react"
import Styled from "styled-components"

export default function({ enabled }) {

  return (
    <Container enabled={enabled}>
      <Dot enabled={enabled}/>
    </Container>
  )
}

const Container = Styled.div`
  width: 48px;
  height: 32px;
  padding: 4px;
  border-radius: 50px;
  transition: background 100ms ease-in-out;
  background: ${props => props.enabled ? "var(--color_primary)" : "black"};
`

const Dot = Styled.div`
  position:relative;
  width: 24px;
  height: 24px;
  border-radius: 20px;
  background: white;
  transition: left 100ms ease-in-out;
  left: ${props => props.enabled ? "16px" : "0"};
`
