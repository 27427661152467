import React, { useState, useEffect } from "react"
import Styled from "styled-components"

import { getEntries, lsGet }  from "../../utils/LocalStorage"
import MyList from "../list/MyList"

export default function() {
  const [myData, setMyData] = useState(false)
  const [myListOpen, setMyListOpen] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  
  const myDataCount = myData ? myData.length : null

  useEffect(() => {
    const my_apis = getEntries("my_apis")
    setMyData(my_apis)

    const has_seen_save_tooltip = lsGet("has_seen_save_tooltip")
    if (!has_seen_save_tooltip && my_apis && my_apis.length) setShowTooltip(true)
  }, [])

  const hideTooltip = () => {
    if (showTooltip) {
      setShowTooltip(false)
      localStorage.setItem("has_seen_save_tooltip", true)
    }
  }

  return (
    <Container onClick={hideTooltip}>
      {showTooltip ? 
        <Tooltip>
          Find and edit your saved items →
        </Tooltip>
        : null
      }
      {myDataCount ? <MyListButton visible={myData} onClick={() => setMyListOpen(!myListOpen)}>{myDataCount}</MyListButton> : null }
      <MyList open={myListOpen} data={myData} />
      <CreateButton href="/new">+</CreateButton>
    </Container>
  )
}

const Container = Styled.div`
  position: fixed;
  z-index: 1;
  top: 24px;
  right: 24px;
  height: 32px;
  display: flex;
  align-items: center;
`

const CreateButton = Styled.a`
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  width: 32px;
  height: 32px;
  margin-left: 8px;
  border-radius: var(--radius_s);
  line-height: 32px;
  text-align: center;
  color: black;
  text-decoration: none;
  background: var(--color_secondary_alpha);
  font-size: var(--font_size_2);
  font-weight: var(--font_weight_2);

  &:hover {
    cursor: pointer;
    background: var(--color_secondary_alpha_hover);
  }

  &:active {
    background: var(--color_secondary_alpha_pressed);
  }
`

const MyListButton = Styled.div`
  display: ${props => props.visible ? "block" : "none"};
  flex-shrink: 0;
  flex-grow: 0;
  width: 32px;
  height: 32px;
  border-radius: var(--radius_s);
  line-height: 32px;
  text-align: center;
  text-decoration: none;
  background: var(--color_primary);
  color: white;
  font-size: var(--font_size_0);
  font-weight: var(--font_weight_0_bold);

  &:hover {
    cursor: pointer;
    background: var(--color_primary_hover);
  }

  &:active {
    background: var(--color_primary_pressed);
  }
`

const Tooltip = Styled.div`
  margin-right: 8px;
  height: 100%;
  line-height: 32px;
  padding: 0 10px;
  border-radius: var(--radius_s);
  background: rgba(0,0,0,1);
  color: white;
  font-size: var(--font_size_0);
  letter-spacing: var(--font_space_0);
  font-weight: var(--font_weight_0);
`