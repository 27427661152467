import React from "react"
import Styled from "styled-components"
import Color from 'color'

import JSONInput from "react-json-editor-ajrm"
import locale from "react-json-editor-ajrm/locale/en"

export default function({ value, color, editable, onChange, onError }) {
  const handleChange = e => {
    const json = e.jsObject
    const error = e.error

    if (!error) {
      onChange(json)
      onError(false)
    } else if (error.reason && error.line) {
      const ErrorString = `${error.reason} at line ${error.line}`
      onError(ErrorString)
    }
  }

  const TextColor = Color(color).darken(0.2).desaturate(0.1);

  return (
    <Container>
      <Label>JSON Data</Label>
      <Code editable={editable}>
        <CodeMask />
        <JSONInput
          id="jsoneditor"
          placeholder={value}
          viewOnly={!editable}
          onChange={handleChange}
          locale={locale}
          confirmGood={false}
          waitAfterKeyPress={1500}
          theme="light_mitsuketa_tribute"
          colors={JSONColors(TextColor)}
          style={JSONStyle(editable)}
        />
      </Code>
      {editable ? 
        <SectionFooter>If your data set needs image links, you can drag and drop them onto this page</SectionFooter>
        : null
      }
    </Container>
  )
}

const Container = Styled.div`
  width: 100%;
  max-width: var(--grid_width);
  padding: var(--grid_padding);
  margin: 0 auto;
`

const Label = Styled.div`
  margin-top: 40px;
  padding: 12px 0;
  font-size: var(--font_size_1);
  letter-spacing: var(--font_space_1);
  font-weight: var(--font_weight_1_bold);
`

const SectionFooter = Styled.div`
  padding: 16px;
  text-align: center;
  color: var(--color_text_secondary);
  font-size: var(--font_size_0);
  letter-spacing: var(--font_space_0);
  font-weight: var(--font_weight_0_light);
`

const Code = Styled.div`
  position: relative;
  padding: 8px;
  border-radius: var(--radius_m);
  background: var(--color_gray_bg);
  overflow: hidden;

  #jsoneditor-outer-box, #jsoneditor-container {
    width: ${props => props.editable ? "100%" : "200%"} !important;
    height: 100% !important;
    overflow: visible !important;
  }
`

const CodeMask = Styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 100px;
  height: 100%;
  background: linear-gradient(90deg, rgba(246,247,248,0) 0%, rgba(246,247,248,1) 80%, rgba(246,247,248,1) 100%);
`

const JSONStyle = editable => {
  return {
    outerBox: {
      background: "none",
      padding: "16px"
    },
    container: {
      background: "none",
      padding: "0"
    },
    body: {
      fontSize: "16px",
      padding: "0"
    },
    labelColumn: {
      display: editable ? "block" : "none",
      opacity: "0.4",
      width: "32px",
      padding: "2px 0px",
      marginRight: "12px",
      marginLeft: "-16px"
    },
    labels: {
      textAlign: "right"
    },
    contentBox: {
      padding: "0"
    },
    warningBox: {
      display: "none"
    }
  }
}

const JSONColors = (color) => {
  return {
    background: "transparent",
    default: "black",
    keys: "black",
    colon: "black",
    string: color,
    number: "#1CAB77",
    primitive: "#6443C7"
  }
}

export const SampleData = {
  "example prop": null,
  nancy_mccarty: {
    A1: {
      userID: "nancy_mccarty",
      userName: "Nancy's McCarty",
      id: "A1",
      score: "0.75",
      date_created: 151208443563,
      date_signed: 151208448055,
      date_approved: 151208471190,
      answers: [
        {
          Q1: true,
          Q2: false
        },
        {
          Q34: "This is an answer",
          Q35: false
        }
      ]
    },
    A2: {
      userID: "nancy_mccarty",
      userName: "Nancy McCarty",
      id: "A2",
      score: 0.9,
      date_created: 151208450090,
      date_signed: false,
      date_approved: false,
      answers: ["No", "No", "No", "Yes", "Yes"]
    }
  },
  george_richardson: {
    A2: {
      userID: "george_richardson",
      userName: "George Richardson",
      id: "A2",
      score: 0.35,
      date_created: 1512076585058,
      date_signed: false,
      date_approved: false,
      answers: ["No", "Yes", "Yes", "Yes", "Yes"]
    }
  },
  tom_hughe: {
    A4: {
      userID: "tom_hughe",
      userName: "Tom Hughe",
      id: "A4",
      score: 0.75,
      date_created: 1512076575026,
      date_signed: 1512076609894,
      date_approved: false,
      answers: ["Yes", "No", "No", "Yes", "No"]
    },
    M1: {
      userID: "tom_hughe",
      userName: "Tom Hughe",
      id: "M1",
      score: false,
      date_created: 1512076587361,
      date_signed: false,
      date_approved: false,
      answers: [false, false, false, false, false]
    }
  },
  heidy_white: {
    L2: {
      userID: "heidy_white",
      userName: "Heidy White",
      id: "L2",
      score: false,
      date_created: 15120765766312,
      date_signed: false,
      date_approved: false,
      answers: [0, 1, 2, 3, 4]
    }
  }
}

