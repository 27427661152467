import React, { useState, Children } from "react"
import Styled from "styled-components"

import { useS3Upload } from "../../utils/S3"
import { uri } from "../../App"
import Card from "./UploadCard"


export default function({ editable, children }) {
  const [uploadedImages, setUploadedImages] = useState([])

  if (!editable) return <>{children}</>

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, uploading } = useS3Upload({
    onUploadReady: url => {
      setUploadedImages([...uploadedImages, url])
    }
  })

  const Cards = uploadedImages.map((image, index) => {
    return <Card key={image} url={image} />
  })

  return (
    <Container>
      <CardsContainer>
        {Cards}
        {uploading ? <Card loading={true} /> : null}
      </CardsContainer>
      <DragTarget {...getRootProps()}>
        <Overlay {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
          <OverlayContent>
            <OverlayTitle>Upload image</OverlayTitle>
            <OverlaySubtitle>Drop a PNG, JPEG, or GIF to get a link <br/>that can be added to this data set</OverlaySubtitle>
          </OverlayContent>
        </Overlay>
        {children}
      </DragTarget>
    </Container>
  )
}

const Container = Styled.div`

`

const CardsContainer = Styled.div`
  position: fixed;
  z-index: 300;
  bottom: 16px;
  right: 16px;
`


const DragTarget = Styled.div`
  outline: none !important;
`

const Overlay = Styled.div`
  position: fixed;
  z-index: 200;
  top: -20%;
  left: -20%;
  width: 140%;
  height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  background-color: ${props => getColor(props)};
  opacity: ${props => getOpacity(props)};
  transition: all 100ms linear;
`

const OverlayContent = Styled.div`
  text-align: center;
`

const OverlayTitle = Styled.div`
  color: white;
  font-size: var(--font_size_3);
  letter-spacing: var(--font_space_3);
  font-weight: var(--font_weight_3);
`

const OverlaySubtitle = Styled.div`
  margin-top: 8px;
  color: white;
  font-size: var(--font_size_1);
  letter-spacing: var(--font_space_1);
  font-weight: var(--font_weight_1);
`

const getColor = (props) => {
  if (props.isDragAccept) {
      return 'rgba(0,0,0,0.85)';
  }
  if (props.isDragReject) {
      return 'rgba(66, 0, 0,0.85)';
  }
  if (props.isDragActive) {
      return 'rgba(33, 150, 243,0.85)';
  }
  return 'transparent';
}


const getOpacity = (props) => {
  if (props.isDragAccept || props.isDragReject || props.isDragActive) {
      return 1;
  }
  return 0;
}
