import React from "react"
import { Link } from "react-router-dom"
import Styled from "styled-components"

import Logo from "../../utils/Logo"
import Account from "../global/Account"

export default function() {
  return (
    <Container>
      <Link to="/">
        <Logo size={"small"} animateColor={true} iconOnly={false} />
      </Link>
      <Account />
    </Container>
  )
}

const Container = Styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  padding: 16px;

  a {
    text-decoration: none;
  }
`
