import React from "react"
import Styled from "styled-components"
import ContentEditable from "react-contenteditable"

export default function({ value, editable, onChange }) {
  const handleChange = e => {
    onChange(e.target.value)
  }

  return (
    <Container>
      <ContentEditable html={value} disabled={!editable} onChange={handleChange} />
    </Container>
  )
}

const Container = Styled.div`
  margin-bottom: 4px;
  text-align: center;
  font-size: var(--font_size_3);
  letter-spacing: var(--font_space_3);
  font-weight: var(--font_weight_3);
`
