import React from "react"
import Styled from "styled-components"

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import { cb } from "react-syntax-highlighter/dist/esm/styles/prism"

export default function({ url }) {
  const exampleBlob = `const [data, setData] = useState(null)
    
fetch("${url}")
  .then(resp => resp.json())
  .then(json => setData(json))
`

  return (
    <Container>
      <Label>Example React usage</Label>
      <Code>
        <SyntaxHighlighter language="javascript" style={cb}>
          {exampleBlob}
        </SyntaxHighlighter>
      </Code>
    </Container>
  )
}

const Container = Styled.div`
  width: 100%;
  max-width: var(--grid_width);
  padding: var(--grid_padding);
  margin: 0 auto;
`

const Label = Styled.div`
  margin-top: 40px;
  padding: 12px 0;
  font-size: var(--font_size_1);
  letter-spacing: var(--font_space_1);
  font-weight: var(--font_weight_1_bold);
`

const Code = Styled.div`
  padding: 8px;
  background: black;
  border-radius: var(--radius_m);

  pre {
    margin: 0 !important;
    background: transparent !important;
  }
`
