import { createGlobalStyle } from "styled-components"
import reset from "styled-reset"

export const GlobalStyle = createGlobalStyle`
  ${reset}

  @import url("https://rsms.me/inter/inter-ui.css");
  
  body {
    --font-sans-serif: "Inter UI var alt", sans-serif;
    font-family: "Inter UI", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.4;
  }

  * { box-sizing: border-box }

  :root {
    --color_primary: #2100DB;
    --color_primary_hover: #3312ED;
    --color_primary_pressed: #2508C9;
    --color_primary_disabled: #8d8e95;

    --color_secondary: #EAECF1;
    --color_secondary_hover: #E1E4EA;
    --color_secondary_pressed: #D8DCE3;
    --color_secondary_disabled: #8d8e95;

    --color_secondary_alpha: rgba(0,0,0,0.07);
    --color_secondary_alpha_hover: rgba(0,0,0,0.09);
    --color_secondary_alpha_pressed: rgba(0,0,0,0.12);


    --color_gray_bg: #F6F7F8;

    --color_selected: #e2e8ff;
    --color_selected_hover: #ebefff;

    --color_text: black;
    --color_text_secondary: #636474;
    --color_text_inaccessible: #a5a9af;
    --color_text_error: #E60017;


    --font_size_0: 14px;
    --font_space_0: -0px;
    --font_weight_0: 600;
    --font_weight_0_light: 500;
    --font_weight_0_bold: 800;

    --font_size_1: 18px;
    --font_space_1: -0.05px;
    --font_weight_1: 400;
    --font_weight_1_bold: 600;

    --font_size_2: 24px;
    --font_space_2: -0.6px;
    --font_weight_2: 400;
    --font_weight_2_bold: 600;

    --font_size_3: 40px;
    --font_space_3: -1.4px;
    --font_weight_3: 900;

    --grid_width: 648px;
    --grid_padding: 0 24px;

    --radius_s: 8px;
    --radius_m: 12px;
    --radius_l: 20px;

    --shadow_s: 0 2px 10px rgba(0, 0, 0, 0.1);
    --shadow_m: 0 4px 20px rgba(0, 0, 0, 0.1);
    --shadow_l: 0 8px 30px rgba(0, 0, 0, 0.15);
  }
`
