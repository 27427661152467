import { useState } from "react"
import { useDropzone } from "react-dropzone"

export function useS3Upload({
  onUploadStart,
  onUploadReady,
  onError
}) {
  const [uploading, setUploading] = useState(false)

  async function onDrop([pendingImage]) {
    // Let the caller know that a file has been selected and a fetch is beginning.
    onUploadStart && onUploadStart()
    setUploading(true)

    let fileName
    if (pendingImage && pendingImage.name) {
      fileName = pendingImage.name
    }
    const randomNumber = Math.floor(Math.random() * 10000)
    const serverPath = `https://api.realdata.dev/upload/${randomNumber}/${fileName}`
    const presignedUploadUrl = await fetch(serverPath).then(response => response.text())

    // Upload the image to our pre-signed URL.
    const response = await fetch(
      new Request(presignedUploadUrl, {
        method: 'PUT',
        body: pendingImage,
        headers: new Headers({
          "Content-Type": "image/*",
        }),
      })
    )

    if (response.status !== 200) {
      // The upload failed, so let's notify the caller.
      setUploading(false)
      onError && onError()
      return
    }

    if (response.url) {
      const url = response.url.split("?")[0]
      onUploadReady(url)
    }

    setUploading(false)
  }

  return {
    ...useDropzone({
      accept: "image/*",
      onDrop,
    }),
    uploading
  }
}