import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { ApolloProvider } from "@apollo/react-hooks"
import ApolloClient from "apollo-boost"

import { GlobalStyle } from "./utils/Theme"
import Home from "./components/Home"
import Form from "./components/Form"
import Detail from "./components/Detail"

const localUri = "http://localhost:3000/graphql"
const prodUri =  "https://api.realdata.dev/graphql"
const devEnv = false
export const uri = devEnv ? localUri : prodUri

const client = new ApolloClient({ uri })


export default function() {
  return (
    <ApolloProvider client={client}>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/new" exact component={Form} />
          <Route path="/edit/:_id" component={Form} />
          <Route path="/api/:path" component={Detail} />
        </Switch>
      </Router>
    </ApolloProvider>
  )
}
