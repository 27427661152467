import React from "react"

export const LogoMark = (
  <svg width="100%" height="100%" viewBox="0 0 166 167">
    <path
      d="M 69 14 C 99.376 14 124 38.624 124 69 C 124 99.376 99.376 124 69 124 C 38.624 124 14 99.376 14 69 C 14 38.624 38.624 14 69 14 Z"
      fill="#61A1EF"
    />
    <path
      d="M 140.718 2.302 C 156.431 19.683 166 42.724 166 68 C 166 122.124 122.124 166 68 166 C 42.697 166 19.634 156.411 2.246 140.668 C -0.199 138.454 0.861 135.207 2.143 133.857 L 134.332 1.668 C 136.999 -0.999 139.608 1.074 140.718 2.302 Z"
      fill="#000000"
    />
  </svg>
)
