import React, { useState } from "react"
import Styled from "styled-components"

import useInterval from "./UseInterval"
import { Colors } from "./Colors"
import { LogoMark } from "./Svgs"

export default function({ size, animateColor, iconOnly }) {
  const [color, setColor] = useState("#4AA1D3")

  useInterval(() => {
    const color = Colors[Math.floor(Math.random() * Colors.length)]
    if (animateColor) setColor(color)
  }, 3000)

  const Text = size === "large" ? LargeText : SmallText

  return (
    <Container>
      <LogoIcon size={size} color={color}>
        {LogoMark}
      </LogoIcon>
      <Text>Real Data</Text>
    </Container>
  )
}

const Container = Styled.div`
  display: flex;
  align-items: center;
`

const LogoIcon = Styled.div`
  display: inline-block;
  width: ${props => (props.size === "large" ? "64px" : "40px")};

  path:first-child {
    fill: ${props => props.color};
    transition: fill 3000ms linear;
  }
`

const LargeText = Styled.span`
  margin-left: 16px;
  font-size: var(--font_size_3);
  letter-spacing: var(--font_space_3);
  font-weight: var(--font_weight_3);
  color: black;
`

const SmallText = Styled.span`
  margin-left: 8px;
  font-size: var(--font_size_2);
  letter-spacing: var(--font_space_2);
  font-weight: 800;
  color: black;
`
