import React, { useState, useEffect } from "react"
import Styled from "styled-components"
import { useQuery } from "@apollo/react-hooks";

import Header from "./Header"
import Icon from "./Icon"
import ColorPicker from "./Color"
import Title from "./Title"
import Description from "./Description"
import Endpoint from "./Endpoint"
import Example from "./Example"
import Content from "./Content"
import Upload from "./Upload"
import Toolbar from "./Toolbar"

import { GET_API } from "../../utils/Database"

export default function({ mode, editable, onSave, onDelete, data }) {
  const [icon, setIcon] = useState(data.icon)
  const [title, setTitle] = useState(data.title)
  const [description, setDescription] = useState(data.description)
  const [color, setColor] = useState(data.color)
  const urlbase = "https://api.realdata.dev/v1/"
  const [path, setPath] = useState(data.path)
  const [content, setContent] = useState(data.content)
  const [discoverable, setDiscoverable] = useState(data.discoverable)
  const [error, setError] = useState("")

  const [isReady, setIsReady] = useState(false)
  const [isEdited, setIsEdited] = useState(false)
  const [primaryAction, setPrimaryAction] = useState(null)

  const checkedAPI = useQuery(GET_API, { variables: { path: path } })

  // Auto fill path with title
  useEffect(() => {
    const NewPath = title.replace(/\s+/g, '-').toLowerCase()
    setPath(NewPath)
  }, [title])

   // Check if path exists
   useEffect(() => {
    if (checkedAPI && checkedAPI.data && checkedAPI.data.APIDetail) {
      const checkedPath = checkedAPI.data.APIDetail.path
      const checkedId = checkedAPI.data.APIDetail._id
      const samePath = checkedPath === path
      const sameId = checkedId === data._id

      if (samePath && !sameId) {
        setError("That path is already taken")
      }
    } else {
      setError(false) // TODO: This breaks the JSON error handling
    }
  }, [path, checkedAPI, data])

  // Shitty validation
  useEffect(() => {
    if (!error) {
      setIsReady(true)
    } 
    else {
      setIsReady(false)
    }
  }, [error])


  // Check for any changes
  useEffect(() => {
    if (data.path !== path || data.title !== title || data.description !== description || data.color !== color || data.icon !== icon || data.content !== content || data.discoverable !== discoverable) {
      setIsEdited(true)
    } else {
      setIsEdited(false)
    }
  }, [data, path, title, description, color, icon, content, discoverable])

  const handlePublish = () => {
    console.log(`discoverable`, discoverable);
    setDiscoverable(!discoverable)
  }

  const handleSave = () => {
    if (isReady) {
      let variables = {
        path,
        title,
        description,
        color,
        icon,
        content: JSON.stringify(content),
        featured: false,
        discoverable: discoverable
      }

      if (data._id) variables = { ...variables, _id: data._id }

      onSave({ variables })
    }
  }

  const handleDelete = () => {
    console.log(`Attempting to delete`, data._id);
    onDelete(data._id)
  }
  
  return (
    <Upload editable={editable}>
      <Container>
        <Header />
        <Hero color={color}>
          <HeroInner>
            <ColorPickerWrapper>
              <ColorPicker value={color} editable={editable} onChange={val => setColor(val)} />
            </ColorPickerWrapper>
            <Icon value={icon} editable={editable} onChange={val => setIcon(val)} />
            <Title value={title} editable={editable} onChange={val => setTitle(val)} />
            <Description
              value={description}
              editable={editable}
              onChange={val => setDescription(val)}
            />
          </HeroInner>
        </Hero>
        <Endpoint urlbase={urlbase} value={path} editable={editable} onChange={val => setPath(val)} />
        <Example url={urlbase + path} />
        <Content
          value={content}
          color={color}
          editable={editable}
          onError={val => {
            setError(val)
          }}
          onChange={val => {
            setContent(val)
          }}
        />
        <Toolbar
          visible={editable}
          ready={isReady}
          edited={isEdited}
          error={error}
          mode={mode}
          published={discoverable}
          onPublish={handlePublish}
          onDelete={handleDelete}
          onSave={handleSave}
        />
      </Container>
    </Upload>
  )
}

const Container = Styled.div`
  padding-bottom: 112px;
`

const Hero = Styled.div`
  position: relative;
  background: ${props => props.color};
  color: black;
`

const HeroInner = Styled.div`
  width: 100%;
  max-width: var(--grid_width);
  padding: var(--grid_padding);
  height: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
`

const ColorPickerWrapper = Styled.div`
  z-index: 100;
  opacity: 0;
  transition: opacity 150ms ease-in-out 300ms;

  ${Hero}:hover & {
    transition: opacity 150ms ease-in-out 0ms;
    opacity: 1;
  }
`
