import React from "react"
import Styled from "styled-components"
import Toggle from "./Toggle"

export default function({ visible, ready, edited, error, mode, published, onPublish, onDelete, onSave }) {
  if (!visible) return null

  return (
    <Container>
      <Bar>
        {error ? <Error>{error}</Error> : null}
        <Actions>
          <TogglePublish onClick={onPublish}>
            <Toggle enabled={published} />
            <ToggleLabel enabled={published}>{published ? "Public" : "Private"}</ToggleLabel>
          </TogglePublish>
          <Spacer />
          {mode === "edit" ? 
            <ButtonDelete onClick={onDelete}>Delete</ButtonDelete>
            :
            null
          }
          <ButtonPrimary disabled={!ready || !edited} onClick={onSave}>
            Save
          </ButtonPrimary>
        </Actions>
      </Bar>
    </Container>
  )
}

const Container = Styled.div`
  position: fixed;
  z-index: 100;
  bottom: 12px;
  left: 0;
  width: 100%;
`

const Bar = Styled.div`
  width: 100%;
  max-width: calc(var(--grid_width) - 24px);
  margin: 0 auto;
  border-radius: var(--radius_m);
  background: white;
  box-shadow: var(--shadow_l);
`

const Actions = Styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
`

const Error = Styled.div`
  min-height: 40px;
  line-height: 1.2em;
  padding: 12px;
  border-top-left-radius: var(--radius_m);
  border-top-right-radius: var(--radius_m);
  background: var(--color_text_error);
  color: white;
  text-align: center;
  font-size: var(--font_size_0);
  letter-spacing: var(--font_space_0);
  font-weight: var(--font_weight_0);
`

const Button = Styled.div`
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  border-radius: var(--radius_s);
  text-align: center;
  font-size: var(--font_size_1);
  letter-spacing: var(--font_space_1);
  font-weight: var(--font_weight_1_bold);
`

const ButtonPrimary = Styled(Button)`
  min-width: 160px;

  background: ${props =>
    props.disabled ? "var(--color_primary_disabled)" : "var(--color_primary)"};
  color: white;
  font-weight: var(--font_weight_1_bold);

  &:hover {
    cursor: ${props => (props.disabled ? "default" : "pointer")};
    background: ${props =>
      props.disabled ? "var(--color_primary_disabled)" : "var(--color_primary_hover)"};
  }

  &:active {
    background: ${props =>
      props.disabled ? "var(--color_primary_disabled)" : "var(--color_primary_pressed)"};
  }
`

const ButtonSecondary = Styled(Button)`
  margin-right: 8px;
  background: var(--color_secondary);
  color: black;
  font-weight: var(--font_weight_1);

  &:hover {
    cursor: pointer;
    background: var(--color_secondary_hover);
  }

  &:active {
    background: var(--color_secondary_pressed);
  }
`


const ButtonDelete = Styled(ButtonSecondary)`
  color: var(--color_text_error);
  font-weight: var(--font_weight_1);
`

const Spacer = Styled.div`
  width: 100%;
`

const TogglePublish = Styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 8px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

const ToggleLabel = Styled.div`
  margin-left: 8px;
  font-size: var(--font_size_1);
  letter-spacing: var(--font_space_1);
  font-weight: var(--font_weight_1_bold);
  color: ${props => props.enabled ? "var(--color_primary)" : "black"};
`