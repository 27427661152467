export const Colors = [
  "#61A1EF",
  "#4AA1D3",
  "#2ED6D6",
  "#24C288",
  "#43D133",
  "#C3D51F",
  "#E3B63B",
  "#FFA46F",
  "#FF9999",
  "#FF94E2",
  "#E494FF",
  "#8D85FF"
]
