import React from "react"
import Styled from "styled-components"

import Row from "./Row"

export default function({open, data}) {
  if (!data) return null
  
  const List = data.map(item => {
    return (
      <Row
        key={item.path}
        primary={item.title}
        icon={item.icon}
        color={item.color}
        link={`/edit/${item._id}`}
        small={true}
      />
    )
  })

  return (
    <Container open={open}>
      {List}
    </Container>
  )
}

const Container = Styled.div`
  display: ${props => props.open ? "block" : "none"};
  position: absolute;
  top: 48px; 
  right: 36px;
  width: 240px;
  padding: 8px 0;
  border-radius: var(--radius_m);
  background: white;
  box-shadow: var(--shadow_m);
`