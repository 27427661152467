import React from "react"
import Styled from "styled-components"
import { useQuery } from "@apollo/react-hooks"

import { GET_API_LIST } from "../utils/Database"
import Logo from "../utils/Logo"
import Account from "./global/Account"
import List from "./list/List"

export default function() {
  const { data } = useQuery(GET_API_LIST)
  const ListData = data && data.APIList

  if (!ListData) return null

  const DiscoverableData = ListData.filter(item => item.discoverable)
  const FeaturedData = DiscoverableData.filter(item => item.featured)
  const NonFeaturedData = DiscoverableData.filter(item => !item.featured)

  return (
    <Container>
      <Account />
      <LogoContainer>
        <Logo size={"large"} animateColor={true} iconOnly={false} />
      </LogoContainer>
      { ListData ? <List data={FeaturedData} featured={true} /> : null }
      <Intro>
        Bring realistic data into your prototype with a simple API. 
        Use one of the existing data sets or create your own.
        
        <IntroButtonGroup>
          <IntroButton href="https://www.framer.com/learn/guide/designing-with-apis/">Read Framer's Guide</IntroButton>
          <IntroButton href="/new">Create a Data Set</IntroButton>
        </IntroButtonGroup>
      </Intro>
      { ListData ? <List data={NonFeaturedData} /> : null }
    </Container>
  )
}

const Container = Styled.div`
  width: 100%;
  max-width: var(--grid_width);
  padding: var(--grid_padding);
  margin: 0 auto;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LogoContainer = Styled.div`
  position: relative;
  margin: 64px;
`

const Intro = Styled.div`
  width: 100%;
  margin: 24px 0;
  padding: 24px;
  padding-top: 20px;
  border-radius: var(--radius_m);
  background: black;
  color: white;
  background: var(--color_secondary);
  color: black;
  text-align: center;
  font-size: var(--font_size_1);
  letter-spacing: var(--font_space_1);
  font-weight: var(--font_weight_1);
`

const IntroButtonGroup = Styled.div`
  width: 100%;
  display: flex;
`

const IntroButton = Styled.a`
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 8px;
  margin-top: 20px;
  margin-right: 12px;
  border-radius: var(--radius_s);
  background: white;
  color: black;
  text-align: center;
  text-decoration: none;
  font-size: var(--font_size_1);
  letter-spacing: var(--font_space_0);
  font-weight: var(--font_weight_0);

  &:hover {
    color: var(--color_primary);
  }

  &:last-child {
    margin-right: 0;
      
    @media (max-width: 800px) {
      display: none;
    }
  }
`
