import React from "react"
import Styled from "styled-components"

import Card from "./Card"
import Row from "./Row"

export default function({data, featured}) {

  const ListItem = featured ? Card : Row

  const list = data.map(item => {
    return (
      <ListItem
        key={item.path}
        primary={item.title}
        secondary={item.description}
        icon={item.icon}
        color={item.color}
        link={`/api/${item.path}`}
      />
    )
  })

  return (
    <Container>
      {featured ? 
        <Grid>{list}</Grid>
        :
        <>{list}</>
      }
    </Container>
  )
}

const Container = Styled.div`
  width: 100%;
`

const Grid = Styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
`
