import React from "react"
import { useQuery } from "@apollo/react-hooks"

import { GET_API } from "../utils/Database"
import EditablePage from "./detail/EditablePage"

export default function({ match }) {
  const { data, loading, error } = useQuery(GET_API, { variables: { path: match.params.path } })

  if (loading || error) return null

  if (!data || !data.APIDetail) {
    setTimeout(()=> {
      window.location.reload(); // TODO: Fix shitty reload hack
    }, 500)
    return null
  }

  console.log(`data`, data);

  const content = JSON.parse(data.APIDetail.content)
  const Data = { ...data.APIDetail, content }

  return <EditablePage key={2} editable={false} data={Data} />
}
